h1,h2,h3,h4,h5,h6,span,form,placeholder{font-family:Poppins}
.content-form{background-color: white;margin: 40px 80px;    box-shadow: 1px 1px 6px 0px #ece4c7;padding: 40px}
.content-form h1{font-size:18px}
.content-form h1 b{background: black;color: white;padding: 9px 10px 4px;border-radius: 30px}
.process{display: flex}
hr{width:60%;margin-left: auto;margin-right: auto;height: 1px;background-color:#666; opacity: 0.5;}
.text-send{background: #151515}
.text-send-space{width:80%;color:white;margin: auto;padding: 80px}
.text-send-space h1{font-size:35px;text-align: center}
.form-group {
    width: 90%;
    margin: 10px 25px;
}
.form-group-solo {
    width: 100%;
		margin: 0 20px;
}

.form-group input, .form-group select,.form-group-solo input {
  background: #15448f17;
border: none;
box-shadow: 0px 0px 3px 0px #05050552;
padding: 15px;
}
.two-column-form {
    display: flex;
    width: 100%;
    justify-content: center;
}
.rendered-form {
    overflow: hidden;
		margin: 40px 0;
}
.one-c{width:100%;}
.tr-c{width:33%}
.card {
    margin: 0 20px;
}
select#mes-exp,select#ano-exp,input#CCV {
    width: 100%;
}
.bot {
    margin: auto;
    text-align: center;
    height: 70px;
		margin-top:50px;
}
button#suscribirte {
    background: #15448f;
    border: none;
    line-height: 31px;
    font-size: 18px;
    font-family: 'Poppins';
    color: white;
    font-weight: 500;
    width: 200px;
    padding: 6px;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}
/*Responsive*/

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media(max-width:600px){
    .content-form{
    padding: 25px;
    margin: 0;
    }
    .two-column-form{
    display:block;
    }
    .form-group{width:80%}
    .field-ano-exp, .field-mes-exp {
        width: 89%;
    }
    input.numberp{
        width: 73%;
        margin-left: 100px;
        padding-left: 16px;
        }
    }
